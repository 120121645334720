<!-- 商品列表组件 -->
<template>
    <div class="container">
        <p v-if="name" class="nav-title flex align-c color-666 font-12">
            <span class="link">
                <router-link :to="{name: 'home'}">首页</router-link>
            </span>
            <i class="el-icon-arrow-right"></i>
            <span>{{name}}</span>
        </p>
        <div v-if="classifyList.length>0" class="classify-container flex font-12">
            <div class="color-999">分类：</div>
            <div class="classify-box color-666 flex-1">
                <span v-for="(item,index) in classifyList" :key="index" 
                    :class="active == index ? 'active' : ''" @click="selectClassify(index)">{{item.name}}</span>
            </div>
        </div>
        <div class="meta-container">
            <span @click="selectFun('all')" :class="sidx=='' ? 'active' : ''">综合</span>
            <span @click="selectFun('marketprice')" :class="sidx=='marketprice' ? 'active' : ''">价格
                <i v-show="sidx!='marketprice'" class="el-icon-top"></i>
                <i v-show="sidx=='marketprice' && sort" class="el-icon-top"></i>
                <i v-show="sidx=='marketprice' && !sort" class="el-icon-bottom"></i>
            </span>
            <span @click="selectFun('salesreal')" :class="sidx=='salesreal' ? 'active' : ''">销量
                <i v-show="sidx!='salesreal'" class="el-icon-top"></i>
                <i v-show="sidx=='salesreal' && sort" class="el-icon-top"></i>
                <i v-show="sidx=='salesreal' && !sort" class="el-icon-bottom"></i>
            </span>
            <span @click="selectFun('isnew')" :class="sidx=='isnew' ? 'active' : ''">最新</span>
        </div>
        <!-- 基药、控销专区返点提示 -->
        <div v-if="tipList && tipList.length>0" class="mt-15">
            提示：
            <span v-for="(item,index) in tipList" :key="index">{{ item.remark }}<i v-if="index < tipList.length-1">，</i></span>
        </div>
        <div class="good-list flex flex-wrap">
            <div class="good-item" v-for="(item, index) in goodList" :key="index">
                <GoodItem :goods="item"/>
            </div>
            <Empty v-if="goodList.length == 0" :type="$route.name == 'search' ? 'search' : ''" title="抱歉，没有找到符合条件的商品" />
        </div>
        <el-pagination v-if="total>perPage" @current-change="pageChange" :current-page="currPage" :page-size="perPage"
                :total="total" layout="total, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>
import GoodItem from '@/components/goodItem'
import Empty from '@/components/empty'

export default {
    name: 'GoodListTemp',
    components: { 
        GoodItem,
        Empty
    },

    props: {
        name: '',
        categoryId: '',
        productType: '',
        control: '',
        medicine: '',
        designatedArea: '',
        type: '',
        title: ''
    },

    watch: {
        title() {
            this.sidx = ''
            this.sort = ''
            this.active = 0
            this.categoryIdData = ''
            this.getGoods(false)
        }
    },

    data() {
        return {
            categoryIdData: this.categoryId,
            classifyList: [],
            goodList: [],
            currPage: 1,
            perPage: 30,
            sidx: '',
            sort: '',
            active: 0,
            total: 0,
            tipList: []
            
        };
    },

    created() {
        if(this.name)
            document.title = this.name
        
        if(!this.categoryIdData)
            this.getClassify()
        else
            this.getGoods(false)
        this.getControlReturn()
    },

    methods: {
        //分类
		getClassify() {
			this.$https.get(this.$api.category,{
                centreId: this.$store.state.userInfo.centreId
            })
            .then((res) =>{
				let list = this.makeTree(res.data)
                list.unshift({name: '全部', id: ''})
                this.classifyList = list
                this.getGoods(false)
            })
		},

        //处理分类数据
        makeTree(data){
            let tree = {};
            data.forEach(item => {
                if (item.enabled &&  (item.status === 1)) {
                    tree[item.id] = item
                }
            })

            data.forEach(item => {
                if (item.enabled &&  (item.status === 1) && (item.parentid > 0)) {
                    let parent = tree[item.parentid]
                    if (parent) {
                        if (!parent.children) {
                            parent.children = []
                        }
                        parent.children.push(item)
                    }
                }
            })

            return data.filter(item => (item.parentid === 0) && ((item.enabled &&  (item.status === 1))))
        },

        getGoods(status) {
            let userInfo = this.$store.state.userInfo
            if(!status) this.currPage = 1
            this.$https.get(this.$api.products,{
                centreId: userInfo.centreId,
                liansuoid: userInfo.upshopTid,
                teamId: userInfo.upshopTid,
                mid: userInfo.id,
                isTeam: '',
                title: this.title,
                isKuaidi: '',
                categoryId: this.categoryIdData,//分类id
                isSpellgroup: '',//拼团商品
                ismiaosha: '',
                ishot: '',
                isvip: '',
                sidx: this.sidx,  //排序字段，存储选择的是价格还是销量还是最新
                order: this.sort ? 'asc' : 'desc',
                productType: this.productType,//0:普通 1预售 2拼团 3秒杀 4预约 5积分兑换 6砍价 10会员商品
                control: this.control,   //是否是控销专区  
                medicine: this.medicine, //是否是基药专区
                designatedArea: this.designatedArea, //是否指定专区
                offset: (this.currPage - 1) * this.perPage,
                limit: this.perPage
            })
            .then((res) =>{
                this.goodList = res.data.rows
                this.total = res.data.total
                document.body.scrollIntoView()
            })
        },

        //筛选类型
        selectFun(type){
            if(type == 'all'){ //综合
                this.sidx = ''
            } else if(type == 'marketprice'){  //价格
                if(this.sidx == 'marketprice'){  //已经选择价格，进行高低排序
                    this.sort = !this.sort
                    this.getGoods(false)
                    return;
                }
                this.sidx = type
            } else if(type == 'salesreal'){  //销量
                if(this.sidx == 'salesreal'){  //已经选择销量，进行高低排序
                    this.sort = !this.sort
                    this.getGoods(false)
                    return;
                }
                this.sidx = type
            } else if(type == 'isnew'){  //最新
                this.sidx = type
            }
            this.sort = true
            this.getGoods(false)
        },

        //选择分类
        selectClassify(index) {
            if(this.active != index) {
                this.active = index
                this.categoryIdData = this.classifyList[index].id
                this.getGoods(false)
            }
        },

        //分页
        pageChange(val) {
            if(this.currPage != val){
                this.currPage = val
                this.getGoods(true)
            }  
        },

        //控销基药返点
        getControlReturn() {
            if(this.control || this.medicine) {
                this.$https.get(this.$api.getControlReturn,{
                    centreId: this.$store.state.userInfo.centreId,
                    type: this.control ? 1 : this.medicine ? 2 : ''
                })
                .then((res)=>{
                    this.tipList = res.list
                })
            }
        },
    }
}
</script>
<style lang='scss' scoped>
    .link{
        &:hover{
            color: var(--blue-color);
        }
    }
    .nav-title{
        padding: 20px 0 15px;
        border-bottom: 1px solid #DEDEDE;
        i{
            margin: 0 5px;
        }
    }
    .classify-container{
        padding: 20px 0 5px;
        border-bottom: 1px solid #DEDEDE;
        .classify-box{
            margin-left: 35px;
            span{
                display: inline-block;
                margin: 0 40px 15px 0;
                cursor: pointer;
                &.active,
                &:hover{
                    color: var(--blue-color);
                }
            }
        }
    }
    .meta-container{
        margin-top: 15px;
        padding: 12px 20px;
        border: 1px solid #DEDEDE;
        span{
            margin-right: 35px;
            cursor: pointer;
            &.active,
            &:hover{
                color: var(--blue-color);
            }
            i{
                font-size: 16px;
                vertical-align: middle;
            }
        }
    }
    .good-list{
        padding: 20px 0;
        .good-item{
            margin:0 12.5px 12.5px 0;
            &:nth-child(5n){
                margin-right: 0;
            }
        }
    }
</style>